.navbar {
  background-color: black;
  height: 80px;
  display: flex;
  justify-content: start;
}

.menu-bars {
  position: absolute;
  right: 20px;
  top: 20px;
  /* margin-left: 50rem; */
  font-size: 2.5rem;
  background: none;
  /* left: 100%; */
}

.nav-menu {
  background-color: black;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%; /* left: -100%; */
  transition: 850ms;
}

.nav-menu.active {
  right: 0px; /* left: 0; */
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #ffb10f;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: black;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}
